import Social from "./Social";
function QuickItems() {
  return (
    <>
      <section className="section-menuitemsquick">
        <div className="accesos">
          <div>
            <div className="wrapper-intranet title">
              <a
                className="link-with-arrow"
                target="_blank"
                rel="noreferrer"
                href="https://soy.utpl.edu.ec"
              >
                Soy UTPL
              </a>
            </div>

            <div className="items">
              <a
                href="https://utpl.edu.ec/mail"
                target="_blank"
                rel="noreferrer"
              >
                Correo electrónico
              </a>

              <a
                href="https://utpl.edu.ec/eva"
                target="_blank"
                rel="noreferrer"
              >
                Entorno Virtual de Aprendizaje
              </a>
              <a
                href="https://utpl.edu.ec/eva"
                target="_blank"
                rel="noreferrer"
              >
                Consejero Estudiantil
              </a>
              <a
                href="https://servicios.utpl.edu.ec/"
                target="_blank"
                rel="noreferrer"
              >
                Sistema Académico
              </a>
              <a
                href="https://portales.utpl.edu.ec/servicios"
                target="_blank"
                rel="noreferrer"
                className="mobile-quickitems"
              >
                Servicios
              </a>

              <a
                href="https://directorio.utpl.edu.ec"
                target="_blank"
                rel="noreferrer"
                className="mobile-quickitems"
              >
                Directorio institucional
              </a>
            </div>
          </div>
        </div>
        <div className="social ">
          <Social></Social>
        </div>
      </section>
    </>
  );
}

export default QuickItems;
