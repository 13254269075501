import Block2Columns from "../../components/Block2ColumnsText";
import DataFetcherTemplate from "../../hooks/fetchTemplate";
import BannerGeneral from "../../components/BannerGeneral";
import BlockImageCabecera from "../../components/BlockImageCabecera";
import Block4ColumnsText from "../../components/Block4ColumnsText";
import Block3Columns from "../../components/Block3Columns";
import BlockRightImage from "../../components/BlockRightImage";
import BlockLeftImage from "../../components/BlockLeftImage";

import Accordion from "../../components/Accordion";
import Notification from "../../components/Notification";
import ModalFixed from "../../components/ModalFixed";
import video from "../../videos/consejeroestudiantil.mp4";

function MIA() {
  const DataDisplay = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <div className="relative">
            <div className="layout8 margin padding ">
              <center>
                <div
                  className="width700"
                  dangerouslySetInnerHTML={{
                    __html: item.field_texto_portada,
                  }}
                />
                <div className="icon350">
                  <img src={item.field_portada}></img>
                </div>
              </center>
              <div className="padding2">
                <a className="link-with-arrow" href="#consejerogrado">
                  Consejeros Estudiantiles Grado
                </a>
                <br></br> <br></br>
                <a className="link-with-arrow" href="#consejeroposgrado">
                  Consejeros Estudiantiles Posgrado
                </a>
                <center>
                  <a className="button" target="_blank" href="https://utpl.edu.ec/eva">
                    Contacta a tu Consejero Estudiantil
                  </a>
                </center>
              </div>
            </div>
            <ModalFixed
              color="#F44336"
              title="Cómo contactar a tu Consejero Estudiantil 📲✨"
              content={
                <>
                  <center>
                    <video width="320" height="auto" controls>
                      <source src={video} type="video/mp4" />
                    </video>
                  </center>
                </>
              }
            ></ModalFixed>
          </div>

          <Notification
            content={
              <div className="padding2">
                <p className="iconsize20">👣🚀💪🏆🎓</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque1,
                  }}
                />
              </div>
            }
          ></Notification>
          <BlockLeftImage
            src={item.field_imagen2}
            content={
              <>
                <p className="iconsize20">🎉🎓</p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque2,
                  }}
                />
              </>
            }
          ></BlockLeftImage>

          <div className="graybg ">
            <BlockRightImage
              src={item.field_imagen4}
              content={
                <>
                  <p className="iconsize20">📚🤝🎓</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.field_bloque4,
                    }}
                  />
                </>
              }
            ></BlockRightImage>

            <div className="layout8 margin">
              <center>
                <div
                  className="width700"
                  dangerouslySetInnerHTML={{
                    __html: item.field_bloque5,
                  }}
                />
              </center>
            </div>

            <div className="padding">
              <Block4ColumnsText
                col1={
                  <>
                    <div className="icon100">
                      <img src={item.field_imagen6} alt="utpl"></img>
                    </div>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque6,
                      }}
                    />{" "}
                  </>
                }
                col2={
                  <>
                    <div className="icon100">
                      <img src={item.field_imagen7} alt="utpl"></img>
                    </div>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />{" "}
                  </>
                }
                col3={
                  <>
                    <div className="icon100">
                      <img src={item.field_imagen8} alt="utpl"></img>
                    </div>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque8,
                      }}
                    />{" "}
                  </>
                }
                col4={
                  <>
                    <div className="icon100">
                      <img src={item.field_imagen9} alt="utpl"></img>
                    </div>{" "}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />{" "}
                  </>
                }
              ></Block4ColumnsText>
            </div>
          </div>
        </>
      ))}
    </>
  );

  const DataDisplay1 = ({ data }) => (
    <>
      {data.map((item, index) => (
        <>
          <div id="consejerogrado">
            <center>
              <div
                className="width700"
                dangerouslySetInnerHTML={{
                  __html: item.field_texto_portada,
                }}
              />
            </center>
            <div className="padding2">
              <Block2Columns
                col1={
                  <>
                    <p className="iconsize20">⚖️👩‍⚖️</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque1,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque2,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
                col2={
                  <>
                    <p className="iconsize20">⚖️👩‍⚖️</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque13,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque14,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block2Columns>
            </div>{" "}
            <div className="padding2">
              <Block2Columns
                col1={
                  <>
                    <p className="iconsize20">📈⚕️</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque5,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque6,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
                col2={
                  <>
                    {" "}
                    <p className="iconsize20"> 📈🧪</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque3,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque4,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block2Columns>
            </div>{" "}
            <div className="padding2">
              <Block2Columns
                col1={
                  <>
                    {" "}
                    <p className="iconsize20">🧠📚</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque9,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque10,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
                col2={
                  <>
                    {" "}
                    <p className="iconsize20">🧠📚</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque7,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque8,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block2Columns>
            </div>
            <div className="padding2">
              <Block2Columns
                col1={
                  <>
                    {" "}
                    <p className="iconsize20">🛠️ 📐</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque11,
                      }}
                    />{" "}
                    <Accordion
                      title="Carreras"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque12,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block2Columns>
            </div>{" "}
          </div>
          <div id="consejeroposgrado">
            <center>
              <div
                className="width700 margin"
                dangerouslySetInnerHTML={{
                  __html: item.field_bloque15,
                }}
              />
            </center>

            <div className="padding2">
              <Block2Columns
                col1={
                  <>
                    {" "}
                    <p className="iconsize20">🎓📚</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque16,
                      }}
                    />{" "}
                    <Accordion
                      title="Posgrados"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque17,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
                col2={
                  <>
                    {" "}
                    <p className="iconsize20">🎓📊</p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.field_bloque18,
                      }}
                    />{" "}
                    <Accordion
                      title="Posgrados"
                      content={
                        <>
                          {" "}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.field_bloque19,
                            }}
                          />
                        </>
                      }
                    ></Accordion>
                  </>
                }
              ></Block2Columns>
            </div>
          </div>
        </>
      ))}
    </>
  );
  return (
    <>
      <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-mia.json">
        {(data) => <DataDisplay data={data} />}
      </DataFetcherTemplate>
      <div className="consejeros padding">
        <DataFetcherTemplate url="https://utpl.edu.ec/carreras/services/servicio-mia-consejeros.json">
          {(data) => <DataDisplay1 data={data} />}
        </DataFetcherTemplate>
      </div>
      <div class="padding">
        <div class="layout8">
          <center>
            <p class="iconsize20">🚀🏆🎓</p>

            <div>
              <h2>
                Tu éxito académico, personal y profesional es nuestra misión
              </h2>
              <h2>
                ¡Aprovecha todo lo que el
                <strong> MIA tiene para ti!</strong>
              </h2>
              <br></br>
              <center>
                  <a className="button" target="_blank" href="https://utpl.edu.ec/eva">
                    Contacta a tu Consejero Estudiantil
                  </a></center>
            </div>
          </center>
        </div>
      </div>
      <BannerGeneral></BannerGeneral>
    </>
  );
}

export default MIA;
